<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" v-if="data.title">
        <h2 class="title-page">
          <span class="main-color"
            >{{ data.title.grade_name }} - {{ data.title.subject_name }} -
            {{ $t("Qualifiers of") }} {{ data.title.quarter_name }}</span
          >
          <!-- | -->
          <!-- <span>{{ data.title.subject_name }}</span> -->
        </h2>
      </v-col>
      <v-col class="mb-5 pr-7" cols="12" md="4" v-if="data">
        <v-row v-if="data" class="justify-content-end pr-10" style="margin-bottom: 0.25em">

          <v-col cols="12" sm="6" md="2" class="pb-1" style="margin-right: -20px">
            <!-- <p > -->
            <span
              v-if="passing_mark_edit_mode"
              class="main-color"
              style="cursor: pointer"
              @click="SaveUpdatePassingMark()"
              :title="$t('Save')"
              >{{ $t("Save") }}</span
            >
          </v-col>
          <v-col cols="12" sm="6" md="2" class="pb-1">
            <span
              v-if="passing_mark_edit_mode"
              style="color: #646464; cursor: pointer"
              @click="cancelUpdatePassingMark()"
              >{{ $t("Cancel") }}</span
            >
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="text-right pr-11"
            v-if="!passing_mark_edit_mode"
          >
            <v-icon
              @click="editPassingMark()"
              :title="$t('Edit')"
              class="main-color edit-icon"
              >edit</v-icon
            >
          </v-col>
        </v-row>
        <v-row class="justify-content-start m-0 p-0">
          <v-col style="margin-bottom: 0.25em;" class="p-1" cols="12" md="10" sm="6" v-if="passingMarkErrorMessage">
            <p
                class="red--text text-right mb-0"
            >
              {{ passingMarkErrorMessage }}
            </p>
          </v-col>
        </v-row>

        <v-row class="marks mt-0">
          <v-col cols="12" md="12">
            <!-- Total Mark -->
            <v-row>
              <v-col cols="12" md="7" sm="6">
                <p>{{ $t("Total Mark") }}</p>
              </v-col>
              <v-col cols="12" md="5">
                <p>
                  <span>{{ data.total_mark }}</span>
                </p>
              </v-col>
            </v-row>
            <!-- Passing Mark -->
            <v-row class="align-items-center">
              <v-col cols="12" md="9" sm="6">
                <p class="key">{{ $t("Passing Mark") }}</p>
              </v-col>
              <v-col cols="12" md="3" sm="6" class="pr-3 pt-2 pb-1">
                <!--<p>-->
                <p class="value" v-if="passing_mark_edit_mode">
                  <v-text-field
                    solo
                    dense
                    style="text-align:right;"
                    v-model="passing_mark"
                  ></v-text-field>
                </p>
                <p v-else class="value">
                  <span >{{ data.passing_mark }}</span>
                </p>
                <!--</p>-->
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="data.qualifiers">
      <v-col
        md="6"
        style="margin-bottom:1.2em; padding-right: 3%"
        v-for="(qualifire, index) in data.qualifiers"
        :key="index"
      >
        <v-form ref="form" v-model="valid">
          <v-card class="card-container" style="max-width:100%">
            <div class="actions">
              <v-icon
                v-if="!qualifire.edit_mode && qualifire.can_edit"
                @click="editQualifire(qualifire)"
                :title="$t('Edit')"
                style="cursor: pointer"
                class="main-color edit-icon"
                >edit</v-icon
              >
              <span
                v-if="qualifire.edit_mode"
                class="main-color pr-3"
                :disabled="!can_save"
                style="cursor: pointer"
                @click="SaveUpdateQualifie(qualifire)"
                >{{ $t("Save") }}</span
              >
              <span
                v-if="qualifire.edit_mode"
                @click="cancelUpdateQualifire(qualifire)"
                style="color: #646464; cursor: pointer"
                >{{ $t("Cancel") }}</span
              >
            </div>

            <v-card-text style="padding: 15px 20px">
              <span
                v-if="qualifire.showErrorTotalMarkPassingMark"
                class="red--text"
              >
                {{
                  $t("The total of Qualifiers Total Marks must be more than the Passing Mark")
                }}
              </span>
              <div class="q-box-title">
                <h2 class="main-color title-qualifire">
                  {{ qualifire.qualifier_title }}
                </h2>
                <span class="icon-toggel"
                  ><i
                    v-if="qualifire.is_open"
                    class="fa fa-chevron-up main-color"
                    @click="closeQualifire(qualifire)"
                  ></i>
                  <i
                    v-if="!qualifire.is_open"
                    class="fa fa-chevron-down main-color"
                    @click="openQualifire(qualifire)"
                  ></i>
                </span>
              </div>
              <div class="content-card" v-if="qualifire.is_open">
                <v-divider class="mx-4 divider"></v-divider>
                <div class="box-marks">
                  <div
                    class="mark-item"
                    :style="qualifire.edit_mode ? 'width: 90%' : 'width: 60%'"
                  >
                    <p class="key">{{ $t("Mark Calculation:") }}</p>
                    <p class="value">
                      <v-radio-group
                        row
                        :rules="[validationRules.required]"
                        v-model="qualifire.calculation"
                        @change="checkCalculationType(qualifire)"
                        v-if="qualifire.edit_mode"
                      >
                        <v-radio :label="$t('Average')" value="1"></v-radio>
                        <v-radio :label="$t('Summation')" value="2"></v-radio>
                      </v-radio-group>
                      <span
                        v-if="qualifire.showErrorMessageTotalCalculation"
                        class="red--text"
                      >
                        {{ $t("Select Calculation Type First") }}
                      </span>
                      <strong v-if="!qualifire.edit_mode">{{
                        qualifire.calculation == null
                          ? ""
                          : qualifire.calculation == 1
                          ? $t("Average")
                          : $t("Summation")
                      }}</strong>
                    </p>
                  </div>
                  <div
                    class="mark-item"
                    :style="
                      qualifire.edit_mode
                        ? 'width: 90%; overflow: hidden;'
                        : 'width: 60%; overflow: hidden;'
                    "
                  >
                    <p class="key">{{ $t("Total Mark:") }}</p>
                    <p class="value">
                      <strong v-if="!qualifire.edit_mode">{{
                        qualifire.total_mark
                      }}</strong>
                      <v-text-field
                        v-if="qualifire.edit_mode"
                        :disabled="qualifire.calculation == 2"
                        v-model="qualifire.total_mark"
                        @keyup="
                          qualifire.activities.forEach((el) => {
                            el.mark = qualifire.total_mark;
                          })
                        "
                        :rules="[
                          validationRules.required,
                          validationRules.floatNumberNotZero,
                        ]"
                        solo
                        dense
                      ></v-text-field>
                      <span
                        v-if="qualifire.showErrorMessageTotalMark == true"
                        class="red--text"
                      >
                        {{ $t("Add Total Mark First") }}
                      </span>
                      <span
                        v-if="qualifire.showErrorTotalMarkNotEqual == true"
                        class="red--text"
                      >
                        {{
                          $t(
                            "Total Mark Should Be Equal Sum Of Activities mark"
                          )
                        }}
                      </span>
                    </p>
                  </div>

                  <!--  -->
                </div>
                <v-divider class="mx-4 divider"></v-divider>
                <div class="activities">
                  <ul class="list-unstyled">
                    <li class="">
                      <v-row>
                        <v-col cols="12" md="4"
                          ><span class="">{{ $t("Activity") }}</span></v-col
                        >
                        <v-col cols="12" md="4"
                          ><span class="value-">{{ $t("Mark") }}</span></v-col
                        >
                        <v-col v-if="qualifire.edit_mode" cols="12" md="2">
                          <span class="icon-add value"
                            ><v-icon
                              small
                              @click="addNewActivity(qualifire)"
                              class="main-color"
                              style="cursor: pointer"
                              :title="$t('Add Activite')"
                              >add_circle</v-icon
                            ></span
                          >
                        </v-col>
                      </v-row>
                    </li>
                    <li
                      v-for="(activity, activityIndex) in qualifire.activities"
                      :key="activityIndex"
                    >
                      <v-row>
                        <v-col cols="12" md="4" lg="4">
                          <span class="">{{ activity.name }}</span>
                          <!-- v-if="qualifire.edit_mode" -->
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                          <v-text-field
                            v-if="
                              qualifire.edit_mode && qualifire.calculation == 2
                            "
                            v-model="activity.mark"
                            @keyup="calcTotal_mark(qualifire)"
                            :rules="[
                              validationRules.required,
                              validationRules.floatNumberNotZero,
                            ]"
                            solo
                            dense
                          ></v-text-field>
                          <span v-else>{{ activity.mark }}</span>
                        </v-col>
                      </v-row>
                    </li>
                    <!-- <li class="">
                    <span class="">Act1</span>
                    <span class="value">20</span>
                  </li>
                  <li class="">
                    <span class="">Act2</span>
                    <span class="value">25</span>
                  </li> -->
                  </ul>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <!-- snackBar -->
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snack"
        :color="color"
        :timeout="timeout"
        :top="true"
        :right="true"
      >
        {{ text }}
        <v-btn color="pink" text @click="snack = false">{{
          $t("Close")
        }}</v-btn>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "AvtiviteiesInQuarter",
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      grade_year_id: "",
      academic_grade_subject_id: "",
      quarter_id: "",
      loading: true,
      data: [],
      valid: true,
      passing_mark_edit_mode: false,
      can_save: "",
      passing_mark: "",
      returned_passing_mark: "",
      // initTotalMark: "",
      form: "",
      //pagination options
      //   page: 0,
      //   circle: true,
      //   length: 0,
      //   totalVisible: "",
      // end pagination

      /* snackBar */
      snack: false,
      text: this.$i18n.t("Item Deleted Successfully"),
      color: "success",
      timeout: 2000,
    };
  },
  watch: {
    // "data.total_mark": {
    //   handler() {
    //     if (Number(this.data.total_mark) <= Number(this.data.passing_mark)) {
    //       console.log("ss");
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    passingMarkErrorMessage: {
      get: function () {
        if (this.passing_mark_edit_mode) {
          if (this.data.total_mark == 0) {
            return this.$i18n.t("Total Mark must be larger than 0.");
          } else if (!this.passing_mark) {
            return this.$i18n.t("This field is required");
          } else if (
            !(
              this.passing_mark &&
              /^\d*\.?(?:\d{1,2})?$/.test(this.passing_mark) &&
              this.passing_mark > 0
            )
          ) {
            return this.$i18n.t("This field only accept positive numbers");
          } else if (this.passing_mark >= this.data.total_mark) {
            return this.$i18n.t(
              `Passing Mark must be less than ${this.$i18n.t("Total Mark")}`
            );
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      set: function (newValue) {
        this.myValue = newValue;
      },
    },
    qualifiersTotalMarkSummation: {
      get: function () {
        let total = 0;
        this.data.qualifiers.forEach((el) => {
          total += Number(el.total_mark);
        });
        return total;
      },
      set: function (newValue) {
        this.myValue = newValue;
      },
    },

    // total_mark_qualifire: function () {
    //   return Date.now()
    // }
  },
  methods: {
    // editRow(item) {
    //   this.addDialog = true;
    //   this.editableItem = item;
    // },

    getData() {
      // getQuarterQualifiersActivities/{academicGradeSubject}/{quarterId}
      axios
        .get(
          this.getApiUrl +
            "/school/getQuarterQualifiersActivities/" +
            this.academic_grade_subject_id +
            "/" +
            this.quarter_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.data = response.data.data;
          this.returned_passing_mark = response.data.data.passing_mark;
          this.initTotalMark = response.data.data.total_mark;
        });
    },
    editQualifire(qualifire) {
      this.data.qualifiers.forEach((el) => {
        el.edit_mode = qualifire == el ? true : false;
        if (el != qualifire) {
          el.can_edit = false;
        }
      });
      qualifire.is_open = true;
    },
    addNewActivity(qualifire) {
      if (qualifire.calculation == null) {
        qualifire.showErrorMessageTotalCalculation = true;
      } else if (qualifire.calculation == 1 && qualifire.total_mark == null) {
        qualifire.showErrorMessageTotalMark = true;
        qualifire.showErrorMessageTotalCalculation = false;
      } else {
        qualifire.showErrorMessageTotalMark = false;
        qualifire.showErrorMessageTotalCalculation = false;
        var countActivites = qualifire.activities.length;
        var mark = qualifire.calculation == 1 ? qualifire.total_mark : 0;
        if (qualifire.calculation == 1) {
          qualifire.activities.forEach((el) => {
            el.mark = mark;
          });
        }

        var row = {
          id: "",
          name: "Act " + Number(countActivites + 1),
          mark: mark,
          subject_qualifier_quarter_id: qualifire.subject_qualifier_quarter_id,
        };
        qualifire.activities.push(row);

        var dataTotalMark = 0;
        this.data.qualifiers.forEach((q) => {
          dataTotalMark += Number(q.total_mark);
        });
        this.data.total_mark = dataTotalMark;
      }
    },
    calcTotal_mark(qualifire) {
      var totalmark = 0;
      qualifire.showErrorTotalMarkPassingMark = false;
      qualifire.activities.forEach((el) => {
        totalmark += Number(el.mark);
      });
      qualifire.total_mark = totalmark;
      // var dataTotalMark = 0;
      // this.data.qualifiers.forEach((q) => {
      //   dataTotalMark += Number(q.total_mark);
      // });
      // this.data.total_mark = dataTotalMark;
      if (
        Number(this.qualifiersTotalMarkSummation) <=
        Number(this.returned_passing_mark)
      ) {
        qualifire.can_save = false;
        qualifire.showErrorTotalMarkPassingMark = true;
      }
    },
    checkCalculationType(qualifire) {
      if (qualifire.calculation == 1) {
        if (qualifire.total_mark) {
          qualifire.activities.forEach((el) => {
            qualifire.total_mark = el.mark;
            // el.mark = qualifire.total_mark / qualifire.activities.length;
          });
        } else {
          qualifire.activities.forEach((el) => {
            el.mark = qualifire.total_mark;
          });
        }
      } else if (qualifire.calculation == 2) {
        qualifire.total_mark = 0;
        qualifire.activities.forEach((el) => {
          qualifire.total_mark += Number(el.mark);
        });

        console.log(qualifire.activities);
      }
    },
    editPassingMark() {
      this.passing_mark_edit_mode = true;
      this.passing_mark = this.returned_passing_mark;
    },
    SaveUpdatePassingMark() {
      if (!this.passingMarkErrorMessage) {
        this.passing_mark_edit_mode = false;
        axios
          .post(
            this.getApiUrl +
              "/school/storeSubjectQuarterPassingMark/" +
              this.academic_grade_subject_id +
              "/" +
              this.quarter_id,
            { passing_mark: this.passing_mark },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.loading = false;
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.cancelUpdatePassingMark();
              this.getData();
            }
          });
      }
    },
    cancelUpdatePassingMark() {
      this.passing_mark_edit_mode = false;
      this.passingMarkErrorMessage = "";
      this.passing_mark = this.returned_passing_mark;
    },
    cancelUpdateQualifire(qualifire) {
      qualifire.edit_mode = false;
      qualifire.showErrorMessageTotalCalculation = false;
      qualifire.showErrorMessageTotalMark = false;
      qualifire.showErrorTotalMarkNotEqual = false;
      this.getData();
    },
    SaveUpdateQualifie(qualifire) {
      var errors = 0;
      qualifire.showErrorTotalMarkPassingMark = false;
      if (qualifire.calculation == null) {
        errors += 1;
        qualifire.showErrorMessageTotalCalculation = true;
      } else if (qualifire.total_mark == null) {
        qualifire.showErrorMessageTotalMark = true;
        errors += 1;
      } /*else if (qualifire.calculation == 2) {
        var totalMark = qualifire.total_mark;
        var ckeckTotalMake = 0;
        qualifire.activities.forEach((el) => {
          ckeckTotalMake += Number(el.mark);
        });
        console.log(totalMark != ckeckTotalMake)
        if (totalMark != ckeckTotalMake) {
          qualifire.showErrorTotalMarkNotEqual = true;
          errors += 1;
        } else {
          qualifire.showErrorTotalMarkNotEqual = true;
        }
      } */
      if (
        Number(this.qualifiersTotalMarkSummation) <=
        Number(this.returned_passing_mark)
      ) {
        errors += 1;
        qualifire.can_save = false;
        qualifire.showErrorTotalMarkPassingMark = true;
      }
      if (errors < 1) {
        axios
          .post(
            this.getApiUrl +
              "/school/storeQuarterQualifiersActivities/" +
              this.academic_grade_subject_id +
              "/" +
              this.quarter_id,
            qualifire,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.cancelUpdateQualifire(qualifire);
              // this.getData();
            }
          });
      }
    },
    openQualifire(qualifire) {
      qualifire.is_open = true;
    },
    closeQualifire(qualifire) {
      qualifire.is_open = false;
    },
  },
  created() {
    this.grade_year_id = this.$router.currentRoute.params.grade_year_id;
    this.academic_grade_subject_id =
      this.$router.currentRoute.params.academic_grade_subject_id;
    this.quarter_id = this.$router.currentRoute.params.quarter_id;
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// end search input
.title-page {
  margin-top: 15px;
}
.marks {
  padding: 20px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.marks p {
  margin-bottom: 10px;
  overflow: hidden;
}
.marks p:last-child {
  margin-bottom: 0;
}
.marks p span {
  float: right;
}
.card-container {
  max-width: 80%;
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
}
.card-container .actions {
  position: absolute;
  top: -39px;
  right: 22px;
}
.card-container .actions i,
.card-container .actions span {
  cursor: pointer;
}
.card-container .actions span {
  margin-left: 5px;
}

.title-qualifire .divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.q-box-title {
  font-size: 29px;
  position: relative;
}

.q-box-title h2 {
  font-size: 25px;
  padding: 0;
  margin: 0;
}
.icon-toggel {
  position: absolute;
  right: 7px;
  top: 25%;
  cursor: pointer;
}
.icon-toggel i {
  font-size: 22px;
}
.box-marks {
  padding: 0 20px;
  // padding-top: 0;
}
.box-marks .mark-item p {
  display: inline-block;
  margin-bottom: 10px;
}
.box-marks .mark-item p.value {
  float: right;
}
.activities li {
  width: 80%;
  margin-bottom: 10px;
  font-size: 17px;
}
.activities li span.value {
  float: right;
  font-size: 15px;
}
.v-input--selection-controls .v-input__slot,
.v-input--selection-controls .v-radio {
  display: inline !important;
}
.value .v-text-field.v-text-field--solo .v-input__control input {
  text-align: right;
}
</style>
